<template>
    <div id="users">
        <!-- 用户列表卡片区 -->
        <el-card class="box-card">

            <div>
                <el-button style="margin-bottom: 10px" @click="returnShop">返回</el-button>
                <el-radio-group v-model="radio" @change="versionSelect" style="margin-left: 20px">
                    <el-radio :label="0">最新版本</el-radio>
                    <el-radio :label="1">所有版本</el-radio>
                </el-radio-group>
            </div>

            <el-table :data="temDate" border style="width: 100%;" :span-method="objectSpanMethod" :loading="loading"
                      @cell-mouse-enter="handleCellMouseEnter"
                      @cell-mouse-leave="handleCellMouseLeave"
                      :row-class-name="tableRowClassName"
                      @cell-click="explainDetail">
                <el-table-column label="软件服务名" prop="name" width="152"></el-table-column>
                <el-table-column label="版本号" width="100" prop="version"></el-table-column>
                <el-table-column label="软件大小(byte)" width="130" prop="softwareSize"></el-table-column>
                <el-table-column label="升级说明" prop="explain" width="400">
                    <template slot-scope="scope">
                        <span slot="reference" class="set-content">{{ scope.row.explain }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="发布日" prop="publishTime" width="120"></el-table-column>
                <el-table-column label="下载日时" prop="downloadTime" width="160"></el-table-column>
                <el-table-column label="操作" width="315">
                    <template slot-scope="scope">
                        <el-button size="small" :loading="scope.row.butLoading" v-if="scope.row.downloadTime==null"
                                   type="warning" icon="el-icon-bottom" plain
                                   @click="download(scope.row.id, scope.row.softwareVersionId)">
                            <span>{{ scope.row.butLoading != true ? "下载" : "下载中" }}</span>
                        </el-button>
                        <el-button size="small" :loading="scope.row.butLoading" type="warning" icon="el-icon-bottom"
                                   plain
                                   v-if="scope.row.downloadTime!=null"
                                   @click="download(scope.row.id, scope.row.softwareVersionId)">
                            <span>{{ scope.row.butLoading != true ? "再次下载" : "下载中" }}</span>
                        </el-button>
                        <el-button size="small" type="primary" icon="el-icon-more" plain
                                   @click="softwareDetail(scope.row)">
                            详细
                        </el-button>
                        <el-button size="small" type="info" icon="el-icon-top" plain v-if="scope.row.downloadTime==null"
                                   @click="install(scope.row)">安装
                        </el-button>
                        <el-button size="small" type="warning" icon="el-icon-top" plain
                                   v-if="scope.row.downloadTime!=null"
                                   @click="install(scope.row)">安装
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 详细对话框 -->
            <el-dialog title="详细" class="class_dialog_hospital" :visible.sync="detailDialogVisible" width="30%"
                       @close="closeDialog">
                <el-card class="box-card">
                    <p>软件名: {{ addForm.softwareName }}</p>
                    <p>版本号: {{ addForm.version }}</p>
                    <p>软件大小(byte): {{ addForm.softwareSize }}</p>
                    <div style="display: flex;width: 70%;margin-top: 10px; margin-bottom: 10px">
                        <div style="flex: 0 0 70px">升级说明:</div>
                        <div>{{ addForm.explain }}</div>
                    </div>
                    <p v-if="addForm.downloadTime!=null">下载时间: {{ addForm.downloadTime }}</p>
                    <p v-if="addForm.downloadTime!=null">下载状态: 已下载</p>
                    <p v-if="addForm.downloadTime==null">下载状态: 未下载</p>
                </el-card>
                <span slot="footer" class="dialog-footer">
                        <el-button @click="closeDialog">取 消</el-button>
                </span>
            </el-dialog>

            <!-- 安装对话框 -->
            <el-dialog title="安装" class="class_dialog_hospital" :visible.sync="addDialogVisible" width="40%"
                       :rules="addFormRules"
                       @close="closeDialogInstall"
                       ref="addFormRef"
            >
                <el-card class="box-card">
                    <p>软件名: {{ addForm.name }}</p>
                    <p>版本号: {{ addForm.version }}</p>
                    <el-table :data="agents" border style="width: 100%;" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column label="Agent末端" prop="agentId" width="200"></el-table-column>
                        <!--<el-table-column label="版本号" width="163" prop="version"></el-table-column>-->
                        <el-table-column label="有效期" width="163" prop="dueDate"></el-table-column>
                    </el-table>
                </el-card>
                <span slot="footer" class="dialog-footer">
                    <el-button type="warning" @click="softInstall(addForm)">安 装</el-button>
                    <el-button @click="closeDialogInstall">取 消</el-button>
                </span>
            </el-dialog>
            <el-drawer
                    title="升级说明"
                    :visible.sync="drawer"
                    :direction="direction">
                <el-card class="box-card" style="width: 80%;word-break:break-all;margin-left: 10%;">
                    <div v-html="addForm.explain"></div>
                </el-card>
            </el-drawer>
        </el-card>
    </div>
</template>
<script>
    import axios from "axios";
    import {provinceAndCityData, regionData} from "element-china-area-data";
    import fileDownload from 'js-file-download'

    export default {

        data() {
            return {
                total: null,
                queryMap: {
                    currentPage: 1,
                    pageSize: 10
                },
                a: 1,
                detailDialogVisible: false,
                addDialogVisible: false,
                softwareList: [],
                drawer: false,
                direction: 'rtl',
                agents: {},
                agent: [],
                downloadDay: "",
                addForm: {},
                currentIndex: null,
                loading: true,
                tableData: [], //临时组
                spanArr: [],
                pos: null,
                options: [
                    {id: 0, name: "试用"},
                    {id: 1, name: "正式"}
                ],
                radio: 0,
                temDate: [],
                ukeyList: [],
                id: null,
                addFormRules: {
                    applyExplain: [
                        {required: true, message: '请输入申请说明', trigger: 'blur'}],
                    type: [
                        {required: true, message: '请选择类别', trigger: 'blur'}
                    ],
                },
                agentInstallList: ''
            };
        },
        methods: {

            /**
             * 安装软件
             * */
            async softInstall(addForm) {

                        if (this.agentInstallList.length == 0) {
                            return this.$notify.error({
                                title: '操作失败',
                                message: '请选择ukey'
                            });
                        } else {
                            let parem = {
                                agentId: this.agentInstallList.toString(),
                                softId: addForm.id,
                                versionId: addForm.softwareVersionId
                            };
                            console.log(parem)
                            const {data: res} = await this.$http.post("/factorymng/agent/install", parem);
                            if (res.success) {
                                this.$notify.success({
                                    title: '操作成功',
                                    message: '安装成功',
                                });
                                this.addForm = {};
                                this.addDialogVisible = false;
                                this.getSoftwareById();
                            } else {
                                return this.$message.error(res.data != null ? res.data : res.data);
                            }
                        }
            },

            /**
             * 遍历
             **/
            handleSelectionChange(selection) {
                console.log(selection)
                selection.forEach(item => {
                    if (this.agentInstallList === '') {
                        this.agentInstallList = item.agentId
                    } else {
                        this.agentInstallList = this.agentInstallList + ',' + item.agentId
                    }
                });
            },
            /**
             * 安装
             **/
            async install(row) {
                this.agents = [];
                this.addDialogVisible = true;
                this.addForm = row;
                this.agentInstallList = '';
                const {data: res} = await this.$http.get("/factorymng/ukey/canapply/list?softId=" + this.addForm.id);
                this.agents = res.data;
                this.agents.version = this.addForm.version;
            },

            /**
             * 返回商店
             **/
            returnShop() {
                this.$router.push("/softwareView/softwareShop")
            },

            /**
             * 鼠标移入
             **/
            handleCellMouseEnter(row, column, rowIndex, columnIndex) {
                //标记当前是哪个分组
                this.currentIndex = row.id
            },

            /**
             * 鼠标移出
             **/
            handleCellMouseLeave() {
                //移出是清空光标
                this.currentIndex = ''
            },

            /**
             * 判断格式
             **/
            tableRowClassName({row, column, rowIndex, columnIndex}) {
                //逻辑判断决定是否修改样式，如果分组一致则使用同一个样式
                if (row.id === this.currentIndex) {
                    return 'hover-bg'
                } else {
                    return ''
                }
            },

            /**
             * 升级说明
             **/
            explainDetail(row, column, cell, event) {
                if (column.label === "升级说明") {
                    this.drawer = true
                    this.addForm.explain = row.explain
                }
            },

            /**
             * 合并单元格
             **/
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    const _row = this.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
                        rowspan: _row,
                        colspan: _col
                    }
                }
            },

            /**
             * 详细
             **/
            async softwareDetail(row) {
                this.detailDialogVisible = true
                const {data: res} = await this.$http.get("/factorymng/software/versionInfo/" + row.softwareVersionId);
                this.addForm = res.data
                this.addForm.tarFile = this.getFileName(this.addForm.tarFile)
                this.addForm.explain = row.explain
                this.addForm.softwareName = row.name
                this.addForm.downloadDay = row.downloadDay
            },

            /**
             * 下载
             **/
            async download(id, softwareVersionId) {
                let param = {softId: id, softwareVersionId: softwareVersionId};
                const {data: res} = await this.$http.post("/factorymng/software/download", param);
                this.getSoftwareById();
                if (res.success) {
                    this.$notify.success({
                        title: '下载成功',
                    });
                } else {
                    return this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                }
            },


            /**
             * id查询软件
             */
            async getSoftwareById() {
                this.spanArr = []
                this.pos = null
                this.tableData = []
                const {data: res} = await this.$http.get("/factorymng/software/" + this.id);
                this.softwareList = res.data;
                this.handleData(this.softwareList)


            },

            /**
             * 获取软件名
             **/
            getFileName(name) {
                if (name == undefined || name == "") {
                    return "";
                }
                let nameList = name.split("/");
                return nameList[nameList.length - 1];
            },

            /**
             * 组装软件版本数据
             **/
            versionSelect() {
                this.temDate = []
                if (this.radio === 0) {
                    this.temDate.push(this.tableData[0])
                } else {
                    this.temDate = this.tableData
                }
            },

            /**
             * 返回数据转为一维参数
             **/
            handleData(data) {
                data.forEach((res, index) => {
                    if (res.softwareVersionList.length != 0) {
                        for (let i = 0; i < res.softwareVersionList.length; i++) {
                            if (res.id == res.softwareVersionList[i].softwareId) {
                                res.version = res.softwareVersionList[i].version
                                res.tarFile = this.getFileName(res.softwareVersionList[i].tarFile)
                                res.publishTime = res.softwareVersionList[i].publishTime
                                res.softwareVersionId = res.softwareVersionList[i].id
                                res.softwareSize = res.softwareVersionList[i].softwareSize
                                res.explain = res.softwareVersionList[i].description
                                res.butLoading = false
                                res.downloadTime = res.softwareVersionList[i].downloadTime
                                this.tableData.push(JSON.parse(JSON.stringify(res)))
                            }
                        }
                    } else {
                        this.tableData.push(res)
                    }
                })
                this.versionSelect()
                for (let i = 0; i < this.tableData.length; i++) {
                    if (i == 0) {
                        this.spanArr.push(1);
                        this.pos = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (this.tableData[i].id == this.tableData[i - 1].id) {
                            this.spanArr[this.pos] += 1;
                            this.spanArr.push(0);
                        } else {
                            this.spanArr.push(1);
                            this.pos = i;
                        }
                    }
                }
            },

            /**
             * 关闭添加弹出框
             */
            closeDialog() {
                this.addForm = {};
                this.detailDialogVisible = false
            },

            /**
             * 关闭添加弹出框
             */
            closeDialogInstall() {
                this.addDialogVisible = false
            },

            /**
             *  改变页码
             */
            handleSizeChange(newSize) {
                this.queryMap.pageSize = newSize;
                this.getSoftwaresById()
            },
            /**
             * 翻页
             */
            handleCurrentChange(current) {
                this.queryMap.currentPage = current;
                this.getSoftwaresById()
            },

            /**
             *  改变页码
             */
            handleSizeCountChange(newSize) {
                this.countMap.pageSize = newSize;
                this.getSoftwaresById()
            },
            /**
             * 翻页
             */
            handleCurrentCountChange(current) {
                this.countMap.currentPage = current;
                this.getSoftwaresById()
            },

            /**
             * 获取商店页面传来软件id
             **/
            getParams() {
                this.id = this.$route.query.softwareId;
            },
        },
        created() {
            this.getParams();
            this.getSoftwareById();
            setTimeout(() => {
                this.loading = false;
            }, 2000);
        }
    };
</script>

<style>
    .mytt {
        width: 150px;
        word-break: break-all;
        word-wrap: break-word;
    }

    .set-content {
        cursor: default;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .el-table__body .el-table__row.hover-bg td {
        background-color: #F5F7FA;
    }


</style>
